import _yaml from "./yaml.js";
var exports = {};
var refractorYaml = _yaml;
exports = tap;
tap.displayName = "tap";
tap.aliases = [];

function tap(Prism) {
  Prism.register(refractorYaml);
  Prism.languages.tap = {
    fail: /not ok[^#{\n\r]*/,
    pass: /ok[^#{\n\r]*/,
    pragma: /pragma [+-][a-z]+/,
    bailout: /bail out!.*/i,
    version: /TAP version \d+/i,
    plan: /\b\d+\.\.\d+(?: +#.*)?/,
    subtest: {
      pattern: /# Subtest(?:: .*)?/,
      greedy: true
    },
    punctuation: /[{}]/,
    directive: /#.*/,
    yamlish: {
      pattern: /(^[ \t]*)---[\s\S]*?[\r\n][ \t]*\.\.\.$/m,
      lookbehind: true,
      inside: Prism.languages.yaml,
      alias: "language-yaml"
    }
  };
}

export default exports;